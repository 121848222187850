export const Categories = () => import('../../components/Categories.vue' /* webpackChunkName: "components/categories" */).then(c => wrapFunctional(c.default || c))
export const ChatBody = () => import('../../components/ChatBody.vue' /* webpackChunkName: "components/chat-body" */).then(c => wrapFunctional(c.default || c))
export const Experts = () => import('../../components/Experts.vue' /* webpackChunkName: "components/experts" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const HeaderCrud = () => import('../../components/HeaderCrud.vue' /* webpackChunkName: "components/header-crud" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const MainRegister = () => import('../../components/MainRegister.vue' /* webpackChunkName: "components/main-register" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Prices = () => import('../../components/Prices.vue' /* webpackChunkName: "components/prices" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const Social = () => import('../../components/Social.vue' /* webpackChunkName: "components/social" */).then(c => wrapFunctional(c.default || c))
export const StarRating = () => import('../../components/StarRating.vue' /* webpackChunkName: "components/star-rating" */).then(c => wrapFunctional(c.default || c))
export const VideoCard = () => import('../../components/VideoCard.vue' /* webpackChunkName: "components/video-card" */).then(c => wrapFunctional(c.default || c))
export const MainLoading = () => import('../../components/main-loading.vue' /* webpackChunkName: "components/main-loading" */).then(c => wrapFunctional(c.default || c))
export const CoreFooter = () => import('../../components/core/footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreLangSwitcher = () => import('../../components/core/lang-switcher.vue' /* webpackChunkName: "components/core-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const CoreMobileMenu = () => import('../../components/core/mobile-menu.vue' /* webpackChunkName: "components/core-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const CoreNavbar = () => import('../../components/core/navbar.vue' /* webpackChunkName: "components/core-navbar" */).then(c => wrapFunctional(c.default || c))
export const CoreYandexMap = () => import('../../components/core/yandex-map.vue' /* webpackChunkName: "components/core-yandex-map" */).then(c => wrapFunctional(c.default || c))
export const ModalsConsultantWarning = () => import('../../components/modals/consultant-warning.vue' /* webpackChunkName: "components/modals-consultant-warning" */).then(c => wrapFunctional(c.default || c))
export const ModalsDelete = () => import('../../components/modals/delete.vue' /* webpackChunkName: "components/modals-delete" */).then(c => wrapFunctional(c.default || c))
export const ModalsFinishChat = () => import('../../components/modals/finish-chat.vue' /* webpackChunkName: "components/modals-finish-chat" */).then(c => wrapFunctional(c.default || c))
export const ModalsImageEditor = () => import('../../components/modals/image-editor.vue' /* webpackChunkName: "components/modals-image-editor" */).then(c => wrapFunctional(c.default || c))
export const ModalsRegisterType = () => import('../../components/modals/register-type.vue' /* webpackChunkName: "components/modals-register-type" */).then(c => wrapFunctional(c.default || c))
export const ModalsRegister = () => import('../../components/modals/register.vue' /* webpackChunkName: "components/modals-register" */).then(c => wrapFunctional(c.default || c))
export const ModalsSendMedia = () => import('../../components/modals/send-media.vue' /* webpackChunkName: "components/modals-send-media" */).then(c => wrapFunctional(c.default || c))
export const ModalsSignin = () => import('../../components/modals/signin.vue' /* webpackChunkName: "components/modals-signin" */).then(c => wrapFunctional(c.default || c))
export const ModalsSuccessful = () => import('../../components/modals/successful.vue' /* webpackChunkName: "components/modals-successful" */).then(c => wrapFunctional(c.default || c))
export const SwipersCategoriesSwiper = () => import('../../components/swipers/categories-swiper.vue' /* webpackChunkName: "components/swipers-categories-swiper" */).then(c => wrapFunctional(c.default || c))
export const SwipersExpertsSwiper = () => import('../../components/swipers/experts-swiper.vue' /* webpackChunkName: "components/swipers-experts-swiper" */).then(c => wrapFunctional(c.default || c))
export const SwipersNewsSwiper = () => import('../../components/swipers/news-swiper.vue' /* webpackChunkName: "components/swipers-news-swiper" */).then(c => wrapFunctional(c.default || c))
export const SwipersPartnersSwiper = () => import('../../components/swipers/partners-swiper.vue' /* webpackChunkName: "components/swipers-partners-swiper" */).then(c => wrapFunctional(c.default || c))
export const SwipersPriceSwiper = () => import('../../components/swipers/price-swiper.vue' /* webpackChunkName: "components/swipers-price-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiBaseDropdown = () => import('../../components/ui/BaseDropdown.vue' /* webpackChunkName: "components/ui-base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiBaseMenu = () => import('../../components/ui/BaseMenu.vue' /* webpackChunkName: "components/ui-base-menu" */).then(c => wrapFunctional(c.default || c))
export const UiBaseModal = () => import('../../components/ui/BaseModal.vue' /* webpackChunkName: "components/ui-base-modal" */).then(c => wrapFunctional(c.default || c))
export const UiBaseRangeInput = () => import('../../components/ui/BaseRangeInput.vue' /* webpackChunkName: "components/ui-base-range-input" */).then(c => wrapFunctional(c.default || c))
export const UiBaseSwiper = () => import('../../components/ui/BaseSwiper.vue' /* webpackChunkName: "components/ui-base-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbs = () => import('../../components/ui/Breadcrumbs.vue' /* webpackChunkName: "components/ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiError = () => import('../../components/ui/Error.vue' /* webpackChunkName: "components/ui-error" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiSkeleton = () => import('../../components/ui/Skeleton.vue' /* webpackChunkName: "components/ui-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UiSlideOver = () => import('../../components/ui/SlideOver.vue' /* webpackChunkName: "components/ui-slide-over" */).then(c => wrapFunctional(c.default || c))
export const UiSwiperOptions = () => import('../../components/ui/swiper-options.js' /* webpackChunkName: "components/ui-swiper-options" */).then(c => wrapFunctional(c.default || c))
export const SwipersNewsSwipersNewSwipers = () => import('../../components/swipers/news-swipers/new-swipers.vue' /* webpackChunkName: "components/swipers-news-swipers-new-swipers" */).then(c => wrapFunctional(c.default || c))
export const SwipersNews = () => import('../../components/swipers/news-swipers/news.vue' /* webpackChunkName: "components/swipers-news" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
